@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.App {
  height: 100%;
}
body {
  background: radial-gradient(100% 100% at 50% 0%, #3D2B52 0%, #2A2442 20.91%, #201F37 100%);
  color: #fff;
}

.content {
  display: grid;
  justify-content: center;
  align-content: top;

}
.form-panel {
  @apply flex flex-col items-center bg-gray-900 p-6 shadow-md shadow-xl;
  border-radius: 20px;
  max-height: 1106px;
}

.pink-example {
  width: auto;
  margin-bottom: 32px;
  border-radius: 0.5rem;
}

.pink-logo {
  height: 45px;
  margin-right: 16px;
  display: none;
}

.pink-network-logo {
  width: 90px;
  margin-right: 16px;
}

.pink-logo-mobile {
  height: 45px;
  margin-right: 16px;
}

.header-container {
  @apply flex justify-between w-full px-2 py-4;
}

.accounts-dropdown {
  @apply w-36;
}

.tweet {
  @apply mb-4;
}

.pink-gallery-container {
  @apply flex flex-col;
}

.gallery-container {
  @apply flex flex-wrap max-w-md mt-2;
  margin-left: -4px;
  margin-right: -4px;
}

.gallery-image-wrapper {
  @apply w-1/3 p-2;
}

.gallery-image {
  @apply w-full rounded-lg;
  cursor: pointer;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;

  &:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 6px #9ecaed;
  }
} 

@media only screen and (min-width: 600px ) {
  .pink-logo {
    display: block;
  }
  .pink-network-logo {
    display: block;
  }
  .pink-logo-mobile {
    display: none;
  }

  .header-container {
    @apply px-8;
  }

  .accounts-dropdown {
    @apply w-64;
  }

  .form-panel {
    width: 420px;
  }

  .pink-gallery-container {
    @apply flex-row-reverse;
  }

  .gallery-container {
    @apply mr-2 mt-0 w-28;
  }

  .gallery-image-wrapper {
    @apply w-full pb-4 pt-0;
  }
}

form {
  width: 100%;
  text-align: center;
}
label, input, select {
  display: block;
}

label {
  @apply text-white font-bold mb-3 ml-0.5 text-left;
}

input {
  @apply w-full bg-gray-800 text-white px-4 py-3 mb-2 border rounded-lg border-gray-700 hover:border-gray-500 focus:outline-none  disabled:opacity-50;
}

select {
  @apply w-full bg-gray-800 text-white px-4 py-3 mb-2 border rounded-lg border-gray-700 hover:border-gray-500 focus:outline-none  disabled:opacity-50;
  height: 50px;
}

input.PrivateSwitchBase-input-4.MuiRadio-colorSecondary.Mui-checked {
  color: #E65EA2;
}

button[type='submit'], button {
  @apply bg-pink-transparent hover:bg-pink-transparent2 text-white font-medium px-6 py-3 border-none disabled:opacity-50 disabled:hover:bg-pink-transparent;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
}
button[type='button'], button {
  @apply bg-pink-transparent hover:bg-pink-transparent2 text-white font-medium px-6 py-3 border-none disabled:opacity-50 disabled:hover:bg-pink-transparent;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
}
.estimations {
@apply text-xs text-left bottom-5 text-gray-300

}
.estimations.has-error {
@apply text-xs text-left absolute bottom-2 text-gray-300

}

a {
  @apply text-pink-400 hover:text-pink-300;
}



@keyframes rise{
  0%{
    bottom:-200px;
  }
  100%{
    bottom:1080px;
  }
}

@keyframes hovering {
  0% {
    transform:translateY(10%);
  }
  100% {
    transform:translateY(20%)
  }
}

.container {
  display: flex;
  height: 100vh;
  max-width: 100%
}

.text-info {
  @apply self-center text-center mx-auto px-4;
}

.loader-image {
  @apply w-fit mx-auto mt-4;
}

.wallet-wrapper {
  @apply flex items-center;
}

.network {
  @apply mr-4
}

h1 {
  font-size: 3rem;
  font-weight: 800;
}

.tag-line {
  @apply text-gray-400;
}

.error-message {
 @apply text-red-500 text-xs text-left
}

.group {
  @apply pb-5 text-left
}
.group:last-child {
  @apply relative pb-2
}

.ui-event {
 @apply border-b border-gray-700 pb-2 mb-2
}
.ui-event:last-child {
 @apply border-0
}
.submit-outcome {
  @apply mb-6 text-sm w-full
}
.form-panel button.disclosure-button {
  @apply flex w-full justify-between rounded-lg bg-pink-900 opacity-100 px-4 py-2 text-left text-sm font-medium text-pink-400 hover:bg-pink-800 focus:outline-none focus-visible:ring
}
.disclosure-panel {
  @apply px-4 pt-4 pb-2 text-sm text-gray-500 w-full mb-6;
}
.disclosure-button[aria-expanded="false"] {
  @apply mb-6;
}
.user-guide {
  @apply text-xs text-gray-300 text-left;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.loader-animation {
  @apply mb-2;
}

.upload-container {
  @apply w-full h-48 flex flex-col items-center justify-center bg-purple-transparent rounded-lg;
}

.buttons-container {
  @apply flex justify-around
}

.social-networks {
  display: flex;
  margin-right: 16px;

}

.social-networks-img {
  width: 28px;
  margin-left: 8px;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;

  &:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
}